@import './variables.scss';

.MuiInputBase-root {
  padding: 8px 12px 8px 8px;
  border-radius: 4px;
  border: 1px solid $Neutral-Gray-300;

  &::before {
    content: none;
  }

  &::after {
    content: none;
  }
}

.MuiNativeSelect-select {
  border: 0;
  padding: 0;
  line-height: 24px;
  border-radius: 8px;

  &:focus {
    background: transparent;
  }
}

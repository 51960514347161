@import '../../../styles/variables.scss';

.paginator {
  display: flex;
  gap: 8px;

  button {
    max-width: 24px;
    min-width: 24px;
    height: 24px;
    padding: 0 4px;
    border: 0;
    background: transparent;
    color: $neutral-gray-500;
    cursor: pointer;
  }

  .previous,
  .next {
    padding: 0;
    border: 1px solid $text-color;
    border-radius: 4px;
  }

  .active {
    color: $primary;
  }
}

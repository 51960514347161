@import '../../styles/variables.scss';

.tab-item {
  display: block;
  padding: 22px 0;
  color: $text-color;
  text-decoration: none;
  text-align: center;
  background: $white;
  border-radius: 4px;

  &.active {
    color: $white;
    background: $primary;

    span {
      font-family: 'Roboto-700';
    }
  }
}

@import '../../styles/variables.scss';

.navbar {
  display: none;
  padding: 20px 0 0 32px;
  background: $white;

  @media (min-width: 1440px) {
    width: 280px;
  }

  @media (min-width: 900px) {
    display: block;
  }

  .logo {
    display: block;
    margin-bottom: 20px;
  }
}

.menu {
  padding: 24px 8px;
  background: $white;

  .menu-icon {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    stroke: #a6b1c1;

    &.ad,
    &.categories {
      stroke: none;
      fill: #a6b1c1;
    }
  }

  @media (min-width: 1000px) {
    padding: 28px 0;
  }

  &-item,
  &-item-child,
  &-item-active {
    padding: 12px 8px 12px;
    color: $text-color;
    text-decoration: none;

    @media (min-width: 1000px) {
      padding: 12px 8px 12px 10px;
    }

    &:hover .menu-icon {
      stroke: $primary;

      &.ad,
      &.categories {
        stroke: none;
        fill: $primary;
      }
    }
  }

  &-item,
  &-item-child,
  &-item-active,
  .MuiTreeItem-label {
    display: flex;
    align-items: center;

    &:hover {
      background-color: $secondaryDark;
      border-radius: 6px 0 0 6px;
    }

    img {
      margin-right: 16px;
    }
  }

  &-item-child {
    padding-left: 42px;
    font-weight: 400;
  }
}

.MuiTreeItem-iconContainer {
  order: 2;
}

.MuiTreeItem-content {
  gap: 32px;

  &.Mui-selected {
    background-color: $nav-selected;
  }
}

.mobile-logout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  padding: 7px 12px;
  border-radius: 8px;
  border: 1px solid $stroke2;

  @media (min-width: 900px) {
    display: none;
  }
}

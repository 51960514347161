@import './variables.scss';

.MuiAlert-root {
  min-width: 300px;
  max-width: 600px;
  margin-top: -24px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

.MuiAlert-colorError {
  background-color: #e08d8d;
  color: $white;

  svg {
    path {
      fill: #fff;
    }
  }
}

.MuiAlert-colorSuccess {
  background-color: #e1f4fc;
  color: $text-color;

  svg {
    path {
      fill: #07a9ed;
    }
  }
}

@import '../../styles/variables.scss';

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  margin-left: -32px;
  margin-right: -32px;
  padding: 24px 32px;
  background: $white;

  &-socials {
    display: flex;
    gap: 16px;
  }

  &-social {
    height: 24px;

    &:hover svg rect {
      fill: #2789f6;
    }

    &:hover svg circle {
      fill: #2789f6;
    }
  }
}

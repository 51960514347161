@import '../../styles/variables.scss';

.MuiFormControlLabel-root {
  margin: 0;
  padding: 0;
}

.MuiFormControlLabel-label {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
}

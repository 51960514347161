$secondaryDark: #f5f6f8;

$text-color: #334d6e;
$nav-bg: #f6f9ff;
$nav-selected: #cadcfd;
$primary: #07a9ed;
$primary100: #e1effe;
$primary700: #0052cc;
$neutral-gray-200: #e4e7ec;
$neutral-gray-500: #637083;
$stroke1: #eceff5;
$stroke2: #e4edfe;
$widget-bg: #fafcff;
$Neutral-Gray-200: #e4e7ec;
$Neutral-Gray-300: #ced2da;
$Neutral-Gray-600: #414e62;
$Gray-1: #333;
$Gradient-Success: linear-gradient(80deg, #40bf7f 0%, #7ad65c 100%);
$warning: #f64c4c;
$white: #fff;
$orange: #ec7d0d;
$success100: #d9f2e5;
$success: #309161;
$error50: #fee7e7;
$error500: #f64c4c;

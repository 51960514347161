.users {
  &-header {
    display: flex;
    gap: 32px;
    margin-bottom: 64px;
  }

  &-control {
    display: flex;
    padding-bottom: 16px;

    @media (min-width: 900px) {
      align-items: flex-end;
      justify-content: space-between;
    }
  }
}

@import '../../styles/variables.scss';

.react-checkbox-tree {
  position: relative;
  padding-left: 24px;
}

.rct-icon {
  background-repeat: no-repeat;
}

.rct-text {
  padding-bottom: 16px;

  label {
    position: absolute;
    left: 0;
    order: 1;
  }
}

.rct-node-icon {
  display: flex;
}

.rct-collapse * {
  width: 14px !important;
}

.rct-checkbox *,
.rct-node-icon * {
  width: 20px !important;
  height: 20px;
}

.rct-node-leaf {
  display: none;
}

.drag-icon {
  fill: $text-color;
  cursor: grab;

  &:hover {
    fill: $primary;
  }
}

.breadcrumbs {
  align-items: center;
  margin-bottom: 24px;
  padding-top: 14px;
  padding-left: 24px;
  padding-bottom: 14px;
  border-top: 1px solid $Neutral-Gray-200;
  border-bottom: 1px solid $Neutral-Gray-200;
}

.search-bar {
  border-top: 1px solid $neutral-gray-200;
  border-left: 1px solid $neutral-gray-200;
  border-right: 1px solid $neutral-gray-200;
}

.tree-search,
.group-search {
  padding: 12px 0 12px 16px;
}

.group-search {
  border-left: 1px solid $neutral-gray-200;
}

.columns {
  position: relative;
  border-top: 1px solid $Neutral-Gray-200;
  border-left: 1px solid $Neutral-Gray-200;
  border-bottom: 1px solid $Neutral-Gray-200;
}

.tree,
.users {
  height: 400px;
  padding: 24px 16px;
  overflow-y: auto;
}

.users {
  position: relative;
}

.tree {
  border-right: $Neutral-Gray-200;

  label {
    display: flex;
    gap: 8px;
  }

  &-domain,
  &-group,
  &-child &-user {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-group,
  &-child,
  &-user {
    margin-left: 24px;
  }
}

.users {
  border-left: 1px solid $Neutral-Gray-200;
}

.submit-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 24px;
}

.dialog-list {
  & li {
    &::before {
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      margin-right: 8px;
      background: #000;
      border-radius: 50%;
    }
  }
}

.collections {
  &-header {
    display: flex;
    gap: 32px;
    margin-bottom: 64px;
  }

  &-list {
    max-width: 1230px;
    margin: auto;
    border: 1px solid #bbb9b9;

    &-header {
      display: grid;
      grid-template-columns: 0.3fr 0.5fr 1fr 1fr 1fr 1fr;
      padding: 24px;
      border-left: 1px solid #bbb9b9;
      border-right: 1px solid #bbb9b9;

      li {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #a6a6a6;
        user-select: none;
      }
    }

    &-items {
      max-width: 1230px;
      margin: auto;

      a {
        color: #333;
      }
    }

    &-item {
      display: grid;
      grid-template-columns: 0.3fr 0.5fr 1fr 1fr 1fr 1fr;
      padding: 16px 12px;
      padding: 24px;
      cursor: grab;

      &:not(:last-child) {
        border-bottom: 1px solid #bbb9b9;
      }

      &:hover {
        background: #f5f4f8;
      }

      a {
        color: #f2994a;

        &:hover {
          text-decoration: underline;
        }
      }

      li {
        font-size: 14px;
        line-height: 20px;
        user-select: none;
      }

      &-controls {
        display: flex;
        align-items: center;
        gap: 44px;
        justify-content: flex-end;
      }

      &-edit,
      &-remove,
      &-move {
        display: inline-block;
        width: 18px;
        height: 18px;
        background-color: transparent;
        background-repeat: no-repeat;
        border: 0;
      }
    }

    &-empty {
      padding: 24px;
      border: 1px solid #bbb9b9;
    }
  }
}

.onscroll-loader {
  position: sticky;
  top: -24px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  height: 400px;
  width: 100%;
  margin: -24px -16px;
  backdrop-filter: blur(2px);

  svg {
    animation-name: rotation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.domains {
  display: block;

  &-add-form,
  &-configurate-ad {
    &-controls {
      margin-top: auto;

      a {
        display: block;
        text-align: center;
      }

      a,
      button,
      .MuiFormControl-root {
        width: 100%;
      }
    }
  }

  &-list {
    max-width: 1230px;
    margin: auto;
    border: 1px solid #bbb9b9;

    &-header {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      padding: 24px;
      border-top: 1px solid #bbb9b9;
      border-left: 1px solid #bbb9b9;
      border-right: 1px solid #bbb9b9;

      li {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #a6a6a6;
        user-select: none;
      }
    }

    &-items {
      position: relative;
      max-width: 1230px;
      margin: auto;

      a {
        color: #333;
      }
    }

    &-item {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      padding: 16px 12px;
      padding: 24px;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid #bbb9b9;
      }

      &:hover {
        background: #f5f4f8;
      }

      a {
        color: #f2994a;

        &:hover {
          text-decoration: underline;
        }
      }

      li {
        font-size: 14px;
        line-height: 20px;
        user-select: none;
      }
    }

    &-empty {
      padding: 24px;
    }

    &-remove {
      &-controls {
        button {
          flex-basis: 47%;
        }
      }

      &-text {
        margin: 16px 0 40px;
      }
    }
  }

  &-update-form {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 24px 0 0;

    &-controls {
      display: flex;
      gap: 16px;
    }
  }
}

.MuiDialog-container {
  & .MuiPaper-root {
    width: 460px;
    max-width: 460px;
  }
}

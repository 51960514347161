@import '../../styles/variables.scss';

.MuiTableCell-head {
  color: $text-color;
  background: $nav-bg;

  svg {
    margin-left: 8px;
    vertical-align: middle;
  }
}

.fetch-error {
  display: block;
  padding: 16px;

  &-img {
    display: block;
    margin: auto;
    width: 80px;
    height: 80px;
    background-image: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_516_965)'%3E%3Cpath d='M40 70C56.5685 70 70 56.5685 70 40C70 23.4315 56.5685 10 40 10C23.4315 10 10 23.4315 10 40C10 56.5685 23.4315 70 40 70Z' stroke='black' stroke-width='3' stroke-miterlimit='10'/%3E%3Cpath d='M40 25V42.5' stroke='black' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M40 54C41.1046 54 42 53.1046 42 52C42 50.8954 41.1046 50 40 50C38.8954 50 38 50.8954 38 52C38 53.1046 38.8954 54 40 54Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_516_965'%3E%3Crect width='80' height='80' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
  }

  &-text {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #000;
  }
}

@import '../../styles/variables.scss';

.MuiButton-root {
  padding: 6px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;

  &.Mui-disabled {
    background-color: #cce0ff;
    border: 1px solid #cce0ff;
    color: #fff;
  }
}

.MuiButton-outlined {
  border-color: $primary;
  color: $primary;
}

.MuiButton-contained {
  background-color: $primary;
  color: #fff;

  &:hover {
    background: #5ccfff;
  }
}

.MuiButton-sizeLarge {
  padding-top: 11px;
  padding-bottom: 11px;
  font-weight: 500;
  line-height: 24px;
}

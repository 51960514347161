@import '../../styles/variables.scss';

.app {
  max-width: 1440px;
  height: 100vh;
  margin: auto;
  padding-top: 88px;

  @media (min-width: 900px) {
    padding-top: 0;
  }
}

.app-right-column {
  flex: 1 0;
  max-width: 100%;
}

.offscreen {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
  color: transparent;
}

.wrapper {
  flex: 1 0;
  padding: 0 16px;
  background: $secondaryDark;

  @media (min-width: 900px) {
    padding: 0 32px;
  }
}

.container {
  padding: 24px;
  background: $widget-bg;
  border-radius: 12px;
  border: 1px solid $stroke2;
}

.MuiModal-root {
  .MuiPaper-root {
    margin: 0;
    padding: 24px;
  }

  .MuiBackdrop-root {
    background-color: rgba(52, 64, 84, 0.6);
    backdrop-filter: blur(2.5px);
  }
}

.circle {
  width: 48px;
  height: 48px;
  padding: 10px;

  &.success {
    border-radius: 50%;
    background: $Gradient-Success;
  }
}

.icon {
  fill: #334d6e;

  &:hover {
    fill: #07a9ed;
  }
}

@import '../../styles/variables.scss';

.header {
  padding: 27px 32px 27px 32px;
  display: flex;
  align-items: center;
  background: $white;
  border-bottom: 1px solid $secondaryDark;

  &-controls {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-left: auto;

    &-dashboard,
    &-logout {
      svg {
        stroke: #a6b1c1;
      }

      &:hover {
        color: $primary;

        svg {
          stroke: $primary;
        }
      }
    }
  }
}
